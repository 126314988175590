.sider {
    /*overflow: auto;*/
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 2;
    background: white;
    box-shadow: 1px 0 30px rgba(0,0,0,.1);
}

.logo {
    margin: 16px;
    text-align: center;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgb(17, 27, 38) !important;
}

.buttonCollapse {
    position: absolute;
    right: -54px;
    top: 14px;
    display: none;
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .buttonCollapse {
        display: none;
    }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
    .buttonCollapse {
        display: none;
    }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .buttonCollapse {
        display: block;
        right: -88px;
    }
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
    .buttonCollapse {
        position: absolute;
        right: -54px;
        top: 14px;
        display: block;
    }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
    .buttonCollapse {
        position: absolute;
        right: -54px;
        top: 14px;
        display: block;
    }
}
