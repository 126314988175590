/* import font family */
@font-face {
    font-family: 'quicksands';
    src: url('../fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
}

/* set font */
body {
    font-family: quicksands, sans-serif !important;
}

/* sider menu item icon & text */
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgb(17, 27, 38) !important;
}
.ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color:rgb(23, 125, 220) !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after {
    position: absolute;
    right: 0;
    border-right: 3px solid #177ddc !important;
}
.ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title:hover {
    color:rgb(23, 125, 220) !important;
}
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgb(23, 125, 220) !important;
}

/* form item  */
.ant-form-item {
    margin-bottom: 10px !important;
}

/* list header */
.ant-list-header, .ant-list-footer {
    padding-bottom: 0 !important;
}

/* pagination */
.ant-table-pagination.ant-pagination {
    margin: 16px 20px !important;
}
